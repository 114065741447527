.App {
  text-align: center;
  overflow: hidden;
}
.pageOuter{
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 40px;
}
.pageInner{
  width: 80%;  
  display: inline-block;
  text-align: center;
}
h2 {
  text-align: center;
}
.padding10{
  padding: 10px;
  box-sizing: border-box;
}