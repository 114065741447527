.banner{
    width: 100%;
    position: relative;
    min-height: 500px;
    padding-bottom: 160px;
}
.bannerImg{
    width: 100%;
    min-height: 500px;
    object-fit: cover;
    background-color: black;
}
.bannerTextBox{
    position: absolute;
    left: 100px;
    top: 20px;
    color: white;
    width: 400px;
}
.bannerTextBox p{
    font-weight: bold;
    font-size: 18px;
}
.bannerButtons{
    width: 100%;
    position: absolute;
    bottom: 10px;
    height: 200px;
    display: flex;
    justify-content: space-around;
}
.bannerButton{
    height: 180px;
    width: 240px;
    border-radius: 10px;
    border: 4px solid white;
    background-color: #c8272d;
    cursor: pointer;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: bold;
    position: relative;
}
.bannerButton:hover{
    background-color: black;
}
.bannerButton img{
    width: 50%;
}
.bannerButton div{
    position: absolute;
    bottom: 10px;
    width: calc(100% - 40px);
    text-align: center;
}
.about{
    width: 100%;
}
.aboutDivRight{
    display: inline-block;
    width: 55%;
    margin-right: 40px;
    font-size: 20px;
    text-align:justify;
}
.aboutDivLeft{
    display: inline-block;
    width: 25%;
    margin: 20px 40px;
    vertical-align: top;

}
.aboutDivLeft img{
    width: 100%;
}
hr{ 
    width: 90%;
}
.services{
    margin: 20px;
    background-color: #333;
    color: white;
    padding: 20px;
}
.columnList{
    width: calc(30% - 20px);
    display: inline-block;
    text-align: left;
    vertical-align: top;
    font-size: 20px;
    font-weight: bold;
}
.star{
    width: 30px;
    background-color: red;
    height: 30px;
    display: inline-block;
}
.star img{
    width: 100%;
}
.customerReviews{
    text-align: center;
}
.customerReview{
    width: 95%;
    border: 1px solid black;
    position: relative;
    display: inline-block;
    padding: 20px;
    padding-top: 50px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
}
.starsContainer{
    position: absolute;
    left: 10px;
    top: 10px;
}   
.reviewText{    
    text-align:justify;
    padding: 10px;
    padding-bottom: 20px;
}
.reviewName{
    position: absolute;
    bottom: 10px;
    right: 10px;
}