.form{
    background-color: rgb(231, 231, 231);
    margin: 20px;
    border: 1px solid black;
    border-radius: 5px;  
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}
.inputArea{
    background-color: white;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 3px;
}
.inputArea input{
    width: 90%;
    height: 30px;
    margin: 10px;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.589) inset;
    border: none;
    padding: 5px 10px;
}
.inputArea select{
    width: calc(90% + 20px);
    height: 40px;
    margin: 5px;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.589) inset;
    border: none;
    
}
.submitButtonArea{
    border: 1px solid black;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    width: calc(100% - 40px);
    margin: 20px;
}
.submitButtonArea button{
    height: 30px;
    width: calc(50% - 20px);
    cursor: pointer;
    margin: 10px;
}