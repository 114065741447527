.headerImage{
    width: 70%;
    margin: 20px;
    display: inline-block;
}
.headerImage img{
    width: 100%;
}
.sellPageP{
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}